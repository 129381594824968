'use client';
import { useChangePathname } from '@edeeone/juan-core/hooks/useChangePathname';
export const ModalPathnameChangeCloseHandler = ({ handleClose, closeIncludeSearchParams, closeEnableSameLinkClick }) => {
    useChangePathname({
        callback: handleClose,
        includeSearchParams: closeIncludeSearchParams,
        enableSameLinkClick: closeEnableSameLinkClick,
    });
    return null;
};
