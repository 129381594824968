'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from '@edeeone/juan-core/components/modal/Modal';
import { Skeleton } from '@edeeone/juan-core/components/skeleton/Skeleton';
import { createContext } from 'react';
import { serverActionModalClassNames } from './ServerActionModal.styles';
import { useServerActionModal } from './useServerActionModal';
export const ServerModalContext = createContext({
    refreshModal: null,
    parentFormContext: null,
});
export const ServerActionModal = ({ styles, }) => {
    const { closeModal, result, pending, props, refreshModal, parentFormContext, } = useServerActionModal();
    if (pending == null) {
        return null;
    }
    const { skeleton, ...resModalProps } = props || {};
    const modalProps = {
        design: 'primary',
        size: 'md',
        closeEnableSameLinkClick: true,
        closeIncludeSearchParams: true,
        ...(resModalProps || {}),
    };
    return (_jsx(Modal, { isOpen: pending != null, setIsOpen: () => {
            return closeModal();
        }, ...modalProps, children: _jsx(ServerModalContext.Provider, { value: {
                refreshModal: async (props) => {
                    await refreshModal(props);
                },
                parentFormContext,
            }, children: !!result ? (result) : !!skeleton ? (skeleton) : (_jsx(DefaultSkeleton, { styles: styles })) }) }));
};
const DefaultSkeleton = ({ styles }) => {
    const finalStyles = serverActionModalClassNames(styles);
    return (_jsxs("div", { className: finalStyles.skeleton_wrapper, children: [_jsx(Skeleton, { count: 1, styles: {
                    skeleton__custom: finalStyles.skeleton_title,
                    bar__custom: finalStyles.skeleton_title_bar,
                } }), _jsx(Skeleton, { count: 8, styles: { skeleton__custom: finalStyles.skeleton_content } })] }));
};
