import { getCAPIEventsConfig, getGTMEventsConfig, } from '@edeeone/juan-core/utils/analyticsEventsUtils';
import { getUserDataForEventsServerAction } from './getUserDataForEvents.serverAction';
import { initDataLayerIfNeeded } from './initDataLayerIfNeeded';
import { spawnCAPIEventServerAction } from './spawnCAPIEvent.serverAction';
import { updateUserDataInDataLayer } from './updateUserDataInDataLayer';
import { mapDataToCAPIData, mapDataToGTMData } from './utils';
/**
 * @description Emits both CAPI and GTM events
 */
export async function emitEvents({ locale, userData, ...eventData }) {
    return emitAnalyticsEvents({
        locale,
        gtmData: mapDataToGTMData(eventData),
        capiData: mapDataToCAPIData(eventData),
        userData,
    });
}
/**
 * @description Creates the CAPI event and takes userData from server on the way. Then it pushes the data to dataLayer (that is consumed by GTM analytics)
 */
async function emitAnalyticsEvents({ capiData, gtmData, locale, userData, }) {
    const gtmEventsConfig = getGTMEventsConfig(locale);
    const capiEventsConfig = getCAPIEventsConfig();
    if (typeof window === 'undefined') {
        return;
    }
    if (!gtmEventsConfig?.gtmId && !capiEventsConfig) {
        return;
    }
    const falseContents = [];
    if (Array.isArray(capiData.customData?.contents)) {
        // NOTE: no contents, cannot have false contents
        capiData.customData.contents = capiData.customData.contents.filter((content) => {
            if (content.productId == null ||
                content.itemPrice == null ||
                content.quantity == null) {
                // should not happen, do this to make debugging easier with better logs
                falseContents.push(content);
                return false;
            }
            return true;
        });
    }
    if (falseContents.length) {
        if (Array.isArray(gtmData.ecommerce?.items)) {
            gtmData.ecommerce.items = gtmData.ecommerce.items.filter((item) => {
                return item.item_id && item.price && item.quantity;
            });
        }
        console.error('Emitting event, that has invalid value in contents:', capiData, 'Array of false contents:', falseContents);
    }
    const { capiUserData, gtmUserData } = await getUserDataForEventsServerAction(userData);
    const gtmDataWithUser = {
        ...gtmData,
        user_data: gtmUserData,
    };
    if (gtmEventsConfig?.logEventsWhenEmitting) {
        console.info('Emitting GTM event:', gtmDataWithUser);
    }
    await Promise.all([
        // NOTE: Sends data to CAPI events - fetches edee API that sends it to facebook
        spawnCAPIEventServerAction(capiData, capiUserData),
        // NOTE: Pushes data to dataLayer, from there it is consumed by analytics tools
        addDataToDataLayer(locale, gtmDataWithUser, userData),
    ]);
}
/**
 * @description Pushes completed data to dataLayer
 */
async function addDataToDataLayer(locale, gtmDataWithUser, userData) {
    const pageLocale = document.getElementsByTagName('html')[0].lang;
    const actualLocale = pageLocale || locale;
    const gtmEventsConfig = getGTMEventsConfig(actualLocale);
    if (!gtmEventsConfig?.gtmId) {
        return;
    }
    if (Array.isArray(window?.dataLayer)) {
        initDataLayerIfNeeded(actualLocale);
        if (userData) {
            await updateUserDataInDataLayer(actualLocale, userData);
        }
        window.dataLayer.push(gtmDataWithUser);
    }
    else {
        // NOTE: Is not initialized yet (initialization is in juan-connector/app/[region]/layout.tsx by <JuanGoogleTagManager />
        // If this happens, it means it is resolved in the same tick as the initialization.
        // We can resolve the function in the next tick, it should be initialized then
        setTimeout(() => {
            addDataToDataLayer(actualLocale, gtmDataWithUser, userData);
        }, 0);
    }
}
