'use client';
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useAfterPaintEffect } from '@edeeone/juan-core/utils/cwv';
import { emitViewPageAnalyticsEvent } from '../eventEmitters';
export function ViewPageAnalyticsEventWrapper(props) {
    const { href, domain, locale, type } = useLocation();
    useAfterPaintEffect(() => {
        emitViewPageAnalyticsEvent({
            ...props,
            domain,
            language: locale,
            locale,
            pageTitle: document.head.querySelector('title')?.textContent || undefined,
            pageType: type,
        });
    }, [href], { priority: 'background' });
    return _jsx(_Fragment, {});
}
