import { useEffect, useRef } from 'react';
import deepEqual from 'fast-deep-equal';
export const useUpdateDeepCompareEffect = (effect, deps) => {
    const isInitialMount = useRef(true);
    const prevDeps = useRef(deps);
    useEffect(() => {
        // skip initial mount effect
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        if (!deepEqual(prevDeps.current, deps)) {
            prevDeps.current = deps;
            return effect();
        }
    }, [deps, effect]);
};
