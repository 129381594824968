'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LocationContext } from '@edeeone/juan-core/routes/LocationContext';
import { parseLocationWithoutRouteFromHrefWithoutNormalization } from '@edeeone/juan-core/utils/parseLocationWithoutRouteFromHrefWithoutNormalization';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { Suspense, useEffect, useRef, useState, } from 'react';
import { useUpdateDeepCompareEffect } from '@edeeone/juan-core/hooks/useUpdateDeepCompareEffect';
function NavigationEventsBase({ onChange }) {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const searchParamsString = searchParams.toString();
    const isFirstRenderPathnameEffect = useRef(true);
    const params = useParams();
    useUpdateDeepCompareEffect(() => {
        const url = `${pathname}${searchParamsString ? `?${searchParamsString}` : ''}`;
        // in next paint
        setTimeout(() => {
            // emit event
            const event = new CustomEvent('locationUrlChange', {
                detail: {
                    url,
                    pathname,
                    timestamp: Date.now(),
                    params,
                },
            });
            document.dispatchEvent(event);
            onChange({ pathname, searchParamsString, url, params });
        }, 0);
    }, [pathname, searchParamsString, params]);
    useEffect(() => {
        // skip first render
        if (isFirstRenderPathnameEffect.current) {
            isFirstRenderPathnameEffect.current = false;
            return;
        }
        // in next paint
        setTimeout(() => {
            // emit event
            const event = new CustomEvent('locationPathnameChange', {
                detail: {
                    pathname,
                    timestamp: Date.now(),
                },
            });
            document.dispatchEvent(event);
        }, 0);
    }, [pathname]);
    return null;
}
export const NavigationEvents = (props) => {
    return (_jsx(Suspense, { children: _jsx(NavigationEventsBase, { ...props }) }));
};
export const LocationProvider = ({ children, location, }) => {
    const [locationState, setLocationState] = useState(location);
    const handleChange = function (e) {
        const { url, params } = e;
        // TODO we don't have any other way yet.
        // we parse the location from the meta tag, because only the internal fragments are updated for navigation in next, not the whole page. This way we get the current location information into the location context.
        const metaLocationTag = document.querySelector('meta[name="location"]');
        const locationFromMeta = JSON.parse(metaLocationTag ? metaLocationTag.content : '{}');
        let locationFromDocument = parseLocationWithoutRouteFromHrefWithoutNormalization(new URL(`${params.region}${url}`, window.location.origin).href);
        const newLocation = {
            // when navigating using window.history state, the meta tag from which the location is parsed may not be available. Because it hasn't been downloaded from the server yet. (this happens especially when navigating with window.history)
            // to do this, you first need to pass the permanent location, then possibly parse everything in the location that comes from window.location
            ...locationState,
            ...locationFromDocument,
            ...locationFromMeta,
            name: metaLocationTag?.content ? locationFromMeta.name : 'Loading...',
        };
        setLocationState(newLocation);
        // if there is no meta tag, it waits for one and repeats the action until there is a meta tag
        if (!metaLocationTag?.content) {
            setTimeout(() => {
                handleChange(e);
            }, 20);
        }
        if (metaLocationTag?.content) {
            // in next paint
            setTimeout(() => {
                // dispatch event if location informations are fully resolved/loaded
                const event = new CustomEvent('locationUrlChangeFull', {
                    detail: {
                        url: e.url,
                        pathname: e.pathname,
                        timestamp: Date.now(),
                        params: e.params,
                        location: newLocation,
                    },
                });
                document.dispatchEvent(event);
            }, 0);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavigationEvents, { onChange: handleChange }), _jsx(LocationContext.Provider, { value: {
                    location: locationState,
                }, children: children })] }));
};
