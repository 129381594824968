import { nanoid } from 'nanoid';
import { RootEventNames } from './eventNames';
import { emitEvents } from './mainEventEmitter';
export async function emitAddToCartAnalyticsEvent({ cartId, customDataLayerData, externalId, items, locale, }) {
    return Promise.all(items.map((item) => {
        const adjustment = item?.adjustment == null ? null : item?.adjustment;
        const eventId = `addToCart_${cartId}_${externalId}_${item.codeShort}_${item.quantity}_${adjustment}_${nanoid()}`;
        return emitEvents({
            locale,
            eventId,
            event: RootEventNames.addToCart,
            ecommerce: {
                value: item.totalPrice,
                items: [item],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        });
    }));
}
export async function emitRemoveFromCartAnalyticsEvent({ cartId, customDataLayerData, externalId, items, locale, }) {
    if (!items) {
        return;
    }
    return Promise.all(items.map((item) => {
        const eventId = `removeFromCart_${cartId}_${externalId}_${item.codeShort}_${item.quantity}_${nanoid()}`;
        return emitEvents({
            locale,
            eventId,
            event: RootEventNames.removeFromCart,
            ecommerce: {
                value: item.totalPrice,
                items: [item],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        });
    }));
}
export async function emitViewCartAnalyticsEvent({ customDataLayerData, items = [], locale, totalPrice = 0, }) {
    const eventId = `viewCart_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.viewCart,
        ecommerce: {
            value: totalPrice,
            items,
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitBeginCheckoutAnalyticsEvent({ customDataLayerData, items = [], locale, totalPrice, }) {
    const eventId = `beginCheckout_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.beginCheckout,
        ecommerce: {
            value: totalPrice,
            items,
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitAddShippingInfoAnalyticsEvent({ countryCode, customDataLayerData, items = [], locale, shippingCode, totalPrice, }) {
    const eventId = `addShippingInfo_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.addShippingInfo,
        ecommerce: {
            countryCode,
            shipping_tier: shippingCode,
            value: totalPrice,
            items,
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitAddPaymentInfoAnalyticsEvent({ cartId, customDataLayerData, externalId, items = [], locale, paymentCode, paymentId, totalPrice, }) {
    const eventId = `addPaymentInfo_${cartId}_${externalId}_${paymentId}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.addPaymentInfo,
        ecommerce: {
            payment_type: paymentCode,
            value: totalPrice,
            items,
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitPurchaseAnalyticsEvent({ pricePolicies, customDataLayerData, externalId, items, locale, orderNumber, shipping, totalPrice, totalPriceOfItems, user, }) {
    if (!orderNumber) {
        return;
    }
    const eventId = `purchase_${externalId}_${orderNumber}`;
    return emitEvents({
        userData: user,
        locale,
        eventId,
        event: RootEventNames.purchase,
        ecommerce: {
            shipping: shipping?.totalPrice?.withoutTax,
            tax: totalPrice?.tax,
            transaction_id: orderNumber,
            user_email: user?.email,
            user_phone: user?.phone,
            value: totalPriceOfItems?.withoutTax,
            coupons: pricePolicies?.reduce((relevantVoucherCodes, pricePolicy) => {
                if (!pricePolicy?.calculationStrategyParameters?.filteredItemId?.length) {
                    const voucherCode = pricePolicy?.calculationStrategyParameters?.calculationParameters
                        ?.voucherCode;
                    if (voucherCode) {
                        relevantVoucherCodes.push(voucherCode);
                    }
                }
                return relevantVoucherCodes;
            }, []),
            items: items?.map((item) => {
                return {
                    ...item,
                    coupons: pricePolicies?.reduce((relevantVoucherCodes, pricePolicy) => {
                        const isRelevant = pricePolicy?.calculationStrategyParameters?.filteredItemId?.some((itemId) => {
                            return itemId === item?.id;
                        });
                        if (isRelevant) {
                            const voucherCode = pricePolicy?.calculationStrategyParameters
                                ?.calculationParameters?.voucherCode;
                            if (voucherCode) {
                                relevantVoucherCodes.push(voucherCode);
                            }
                        }
                        return relevantVoucherCodes;
                    }, []),
                };
            }),
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitViewItemAnalyticsEvent({ codeShortOfVariants, customDataLayerData, locale, ...item }) {
    const eventId = `viewContent_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.viewItem,
        ecommerce: {
            value: item.totalPrice,
            contentIdsOverride: codeShortOfVariants,
            items: [item],
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitSelectItemAnalyticsEvent({ customDataLayerData, locale, ...item }) {
    const eventId = `selectItem_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.selectItem,
        ecommerce: {
            items: [item],
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitViewItemListAnalyticsEvent({ customDataLayerData, items, listId, locale, }) {
    if (!listId) {
        return;
    }
    const eventId = `viewContent_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.viewContent,
        ecommerce: {
            item_list_id: listId,
            items,
        },
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitAddToWishListAnalyticsEvent({ codeShortOfVariants, customDataLayerData, externalId, items, locale, totalPrice, wishListCode, }) {
    return Promise.all(items?.map((item) => {
        const eventId = `wishlist_${externalId}_${wishListCode}_${item?.codeShort}`;
        return emitEvents({
            locale,
            eventId,
            event: RootEventNames.addToWishlist,
            ecommerce: {
                value: totalPrice,
                contentIdsOverride: codeShortOfVariants,
                items: [item],
            },
            ...(customDataLayerData ? { customDataLayerData } : {}),
        });
    }) || []);
}
export async function emitLeadAnalyticsEvent({ customDataLayerData, locale, login, webSystemId, }) {
    const eventId = `completeRegistration_${webSystemId}_${login}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.lead,
        content: 'registration_activated',
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitViewPageAnalyticsEvent({ customDataLayerData, domain, language, locale, pageTitle, pageType, }) {
    const eventId = `viewPage_${nanoid()}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.fgPageView,
        language,
        site: domain,
        title: pageTitle,
        type: pageType,
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
export async function emitSubmitFormAnalyticsEvent({ customDataLayerData, mailHash, formId, formName, formText, formType, locale, webSystemId, }) {
    const eventId = `lead_${webSystemId}_${mailHash}`;
    return emitEvents({
        locale,
        eventId,
        event: RootEventNames.form,
        form: {
            id: formId,
            name: formName,
            type: formType,
            text: formText,
        },
        _clear: true,
        ...(customDataLayerData ? { customDataLayerData } : {}),
    });
}
