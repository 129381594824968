import { ConsentCookie } from '@edeeone/juan-core/constants';
import { getCookiesFromDocument } from '@edeeone/juan-core/Cookies';
import { DENIED_CONSENT_VALUE, getConsentConfig, updateConsents, } from '@edeeone/juan-core/hooks/useConsent';
import { getGTMEventsConfig, gtag, } from '@edeeone/juan-core/utils/analyticsEventsUtils';
export function initDataLayerIfNeeded(locale) {
    const cookieConsents = getCookiesFromDocument()[ConsentCookie];
    const isConsentInitEventMissing = !window.dataLayer.some((eventDetail) => {
        return eventDetail?.event === 'consent_init';
    });
    if (isConsentInitEventMissing) {
        const defaultConsents = {};
        const consentConfig = getConsentConfig(locale);
        Object.keys(consentConfig).forEach((consentName) => {
            defaultConsents[consentName] = DENIED_CONSENT_VALUE;
        });
        const gtmEventsConfig = getGTMEventsConfig(locale);
        if (gtmEventsConfig?.logEventsWhenEmitting) {
            console.info('Init dataLayer. Default consents:', defaultConsents);
        }
        gtag('consent', 'default', defaultConsents);
        window.dataLayer.push({
            event: 'consent_init',
            consent: defaultConsents,
            _clear: true,
        });
        if (cookieConsents?.length) {
            updateConsents(cookieConsents, locale);
        }
    }
}
