'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getGTMEventsConfig } from '@edeeone/juan-core/utils/analyticsEventsUtils';
import { GoogleTagManager } from '@next/third-parties/google';
export function JuanGoogleTagManager() {
    const { locale } = useLocation();
    const gtmEventsConfig = getGTMEventsConfig(locale);
    if (gtmEventsConfig?.gtmId) {
        return _jsx(GoogleTagManager, { gtmId: gtmEventsConfig.gtmId });
    }
}
